<template>
  <div>
    <span
      ref="badgeRef"
      class="badge e-title-2"
      :style="{ borderColor: color, color: color }"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
import { reactive, ref } from "vue";

export default {
  name: "Badge",
  props: {
    color: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "text",
    },
  },
  setup() {
    const badgeRef = ref();

    const state = reactive({});

    return { state, badgeRef };
  },
};
</script>

<style scoped>
.badge {
  border-radius: 4px;
  padding: 1.5px 7px;
  border: 1px solid;
}
</style>
