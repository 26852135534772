<template>
  <nav>
    <ul class="pagination">
      <li>
        <button-basic
          class="prev-button"
          shape="round"
          bg-color="#E6EAEF"
          padding="12px"
          @action="
            state.activePrevBtn
              ? actions.getPageData(state.pageInfo.currentPage - 1)
              : ''
          "
        >
          <template #icon>
            <arrow-icon
              direction="left"
              :fill-color="state.activePrevBtn ? '#404040' : '#C2C2C2'"
            ></arrow-icon>
          </template>
        </button-basic>
      </li>
      <li v-if="state.showPrevSpread">
        <button-text
          text-size="s1"
          text="1"
          padding="0 14px"
          :text-weight="1 === pagination.currentPage ? 'title' : 'text'"
          :color="1 === pagination.currentPage ? '#404040' : '#A8A8A8'"
          @click="actions.getPageData(1)"
        ></button-text>
        <i
          v-if="state.pageInfo.currentPage !== 4"
          class="fa-solid fa-ellipsis"
        ></i>
      </li>
      <li v-for="(item, index) in state.pageList" :key="index + 1">
        <button-text
          text-size="s1"
          :text="`${item.page}`"
          padding="0 14px"
          :text-weight="item.page === pagination.currentPage ? 'title' : 'text'"
          :color="item.page === pagination.currentPage ? '#404040' : '#A8A8A8'"
          @click="actions.getPageData(item.page)"
        ></button-text>
      </li>

      <li v-if="state.showNextSpread">
        <span>&#183;&#183;&#183;</span>

        <button-text
          :text="state.pageInfo.lastPage"
          padding="0 14px"
          text-size="s1"
          :text-weight="
            state.pageInfo.lastPage === pagination.currentPage
              ? 'title'
              : 'text'
          "
          :color="
            state.pageInfo.lastPage === pagination.currentPage
              ? '#404040'
              : '#A8A8A8'
          "
          @click="actions.getPageData(state.pageInfo.lastPage)"
        ></button-text>
      </li>
      <li>
        <button-basic
          class="next-button"
          shape="round"
          bg-color="#E6EAEF"
          padding="12px"
          @action="
            state.activeNextButton
              ? actions.getPageData(state.pageInfo.currentPage + 1)
              : ''
          "
        >
          <template #icon>
            <arrow-icon
              :fill-color="state.activeNextButton ? '#404040' : '#C2C2C2'"
            ></arrow-icon>
          </template>
        </button-basic>
      </li>
    </ul>
  </nav>
</template>

<script>
import { computed, reactive } from "vue";
import ArrowIcon from "../icons/ArrowIcon";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";

export default {
  name: "Pagination",
  components: { ButtonText, ButtonBasic, ArrowIcon },
  props: {
    pagination: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const state = reactive({
      pageInfo: computed(() => {
        return props.pagination;
      }),
      showPrevSpread: computed(() => {
        return state.pageInfo.lastPage > 5 && state.pageInfo.currentPage >= 4;
      }),
      showNextSpread: computed(() => {
        return (
          state.pageInfo.lastPage > 5 &&
          state.pageInfo.currentPage < state.pageInfo.lastPage - 2
        );
      }),
      pageList: computed(() => {
        let pageArr = [];

        if (state.pageInfo.lastPage <= 5) {
          for (let i = 0; i < state.pageInfo.lastPage; i++) {
            pageArr.push({ page: i + 1 });
          }
          return pageArr;
        }

        if (state.pageInfo.currentPage < 4) {
          for (let i = 0; i < 5; i++) {
            pageArr.push({ page: i + 1 });
          }
          return pageArr;
        }
        if (state.pageInfo.currentPage + 2 >= state.pageInfo.lastPage) {
          for (
            let i = state.pageInfo.lastPage - 5;
            i < state.pageInfo.lastPage;
            i++
          ) {
            pageArr.push({ page: i + 1 });
          }
          return pageArr;
        }

        for (
          let i = state.pageInfo.currentPage - 3;
          i < state.pageInfo.currentPage + 2;
          i++
        ) {
          pageArr.push({ page: i + 1 });
        }

        return pageArr;
      }),
      activePrevBtn: computed(() => {
        return state.pageInfo.currentPage > 1;
      }),
      activeNextButton: computed(() => {
        return state.pageInfo.currentPage !== state.pageInfo.lastPage;
      }),
    });

    const actions = {
      getPageData: (i) => {
        if (i !== props.pagination.currentPage) {
          context.emit("getPageData", i);
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped>
nav {
  display: flex;
}

.pagination {
  margin: 0 auto;
  display: flex;
}

.pagination li {
  display: flex;
  align-items: center;
}

.pagination li .prev-button {
  margin-right: 28px;
  width: 40px;
  height: 40px;
}

.pagination li .next-button {
  margin-left: 28px;
  width: 40px;
  height: 40px;
}
</style>
