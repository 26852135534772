<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>

    <div v-if="!state.pageLoading">
      <page-header
        title="상세 정보"
        :back-button="true"
        :route-name="{
          name: 'console.clubs',
        }"
      ></page-header>

      <div class="club-detail-wrapper">
        <club-card
          :club="state.club"
          @editClub="actions.goToClubEdit()"
        ></club-card>

        <div class="tab-wrapper">
          <button-tab
            text="세션"
            :active="state.activeTab === 'session'"
            @action="actions.changeTab('session')"
          ></button-tab>
          <button-tab
            text="인사이트"
            :active="state.activeTab === 'insight'"
            @action="actions.changeTab('insight')"
          ></button-tab>
          <button-tab
            text="멤버"
            :active="state.activeTab === 'member'"
            @action="actions.changeTab('member')"
          ></button-tab>
          <button-tab
            text="기대평"
            :active="state.activeTab === 'expect-articles'"
            @action="actions.changeTab('expect-articles')"
          ></button-tab>
        </div>

        <!--	세션 탭 -->
        <div v-if="state.activeTab === 'session'">
          <div class="session-header sub-text-s3 text-gray-second">
            <span class="col1">세션</span>
            <span class="col2">기능</span>
          </div>

          <!--  클럽 세션  -->
          <div
            v-if="state.club.sessions.length > 0"
            class="session-list-wrapper"
          >
            <ul>
              <li
                v-for="(clubSession, index) in state.club.sessions"
                :key="`club-session-${clubSession.resourceId}`"
              >
                <club-session-item
                  :index="index"
                  :session="clubSession"
                  :club="state.club"
                  @openLiveFinishModal="actions.openLiveFinishModal()"
                ></club-session-item>
              </li>
            </ul>
          </div>

          <!--	session empty  -->
          <div
            v-if="state.club.sessions.length === 0"
            class="session-empty-card"
          >
            <div class="directory-img">
              <img src="/assets/images/club/session_empty.png" width="72" />
            </div>
            <p class="sub-text-s2 text-gray-second">
              생성된 세션이 없습니다.<br />
              세션 생성 버튼을 통해 세션을 구성해보세요!
            </p>
            <button class="creat-btn" @click="actions.goToClubEdit()">
              <span class="sub-title-s2 text-default">세션 추가하기</span>
              <arrow-icon class="arrow-icon"></arrow-icon>
            </button>
          </div>
        </div>

        <!-- 인싸이트 -->
        <div v-if="state.activeTab === 'insight'">
          <div class="insight-ready">
            <p class="text-gray-second sub-text-s2">
              현재 준비중인 페이지입니다.<br />
              빠른 시일내에 준비하여 찾아뵙겠습니다.
            </p>
            <img
              class="mo-img"
              src="/assets/images/temporary/club_insight_ready_mo.png"
            />
            <img
              class="pc-img"
              src="/assets/images/temporary/club_insight_ready.png"
            />
          </div>
        </div>

        <!--	멤버 탭  -->
        <div v-if="state.activeTab === 'member'">
          <div class="member-search-wrapper">
            <input-search-new
              v-model="state.searchKey"
              shape="round"
              placeholder="검색어를 입력해주세요."
              search-type="pressEnter"
              @search="getClubMembers()"
            ></input-search-new>
          </div>

          <div class="member-header sub-text-s3 text-gray-second">
            <span class="col1">유형</span>
            <span class="col2">닉네임</span>
            <span class="col3">이메일</span>
            <span class="col4">결제 티켓</span>
            <span class="col5">결제 금액</span>
            <span class="col6"> 이용 포인트 </span>
            <span class="col7">구매 일시</span>
            <span class="col8"> 최근 접속 일시 </span>
          </div>

          <div v-if="state.clubMembers.length > 0" class="member-list-wrapper">
            <ul>
              <li
                v-for="clubMember in state.clubMembers"
                :key="`club-member-${clubMember.resourceId}`"
              >
                <club-member-item
                  :club="state.club"
                  :club-member="clubMember"
                ></club-member-item>
              </li>
            </ul>

            <div
              v-if="state.clubMembersMeta.pagination.lastPage !== 1"
              class="member-pagination"
            >
              <pagination
                :pagination="state.clubMembersMeta.pagination"
                @getPageData="(page) => getClubMembers({ page: page })"
              ></pagination>
            </div>
          </div>

          <div v-if="state.clubMembers.length === 0" class="member-empty-card">
            <div class="directory-img">
              <img src="/assets/images/club/member_empty.png" width="72" />
            </div>
            <p class="sub-text-s2 text-gray-second">아직 멤버가 없습니다.</p>
          </div>
        </div>

        <div v-if="state.activeTab === 'expect-articles'">
          <div class="list-section">
            <ul v-if="state.isArticles" class="review-list">
              <li
                v-for="(article, index) in state.club.community.articles"
                :key="`lecture-article-${article.resourceId}`"
              >
                <review-item
                  :index="index"
                  :article="article"
                  @successArticleReport="
                    actions.showArticleReportSuccessModal()
                  "
                ></review-item>
                <!--                @syncLectureArticles="getLectureArticles()"-->
                <!--                @successArticleReport="-->
                <!--                actions.showArticleReportSuccessModal()-->
                <!--                "-->
                <!--                @deleteArticle="actions.deleteArticle(article.resourceId)"-->
                <!--                @reportComment="getLectureArticles()"-->
                <!--                @deleteComment="getLectureArticles()"-->
                <!--                @editComment="getLectureArticles()"-->
              </li>
            </ul>

            <empty-list
              v-if="!state.isArticles"
              icon-image="/assets/images/empties/empty_heart.jpeg"
              text="아직 멤버가 남긴 글이 없어요"
            ></empty-list>
          </div>
        </div>
      </div>
    </div>

    <teleport :disabled="true">
      <warning-modal
        v-if="state.showLiveFinishWarningModal"
        :show-cancel-button="false"
        warning-title="종료된 라이브 입니다."
        confirm-text="확인"
        @confirm="actions.confirmLiveWarning()"
        @hideModal="actions.closeLiveFinishModal()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import PageHeader from "../../../components/console/headers/PageHeader";
import PageLoading from "../../../components/console/loadings/PageLoading";
import { computed, onBeforeMount, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import ClubCard from "./ClubCard/ClubCard";
import { useRoute, useRouter } from "vue-router";
import ButtonTab from "../../../components/console/buttons/ButtonTab";
import ClubMemberItem from "./ClubMemberItem/ClubMemberItem";
import ClubSessionItem from "./ClubSessionItem/ClubSessionItem";
import ArrowIcon from "../../../components/console/icons/ArrowIcon";
import WarningModal from "../../../components/console/modals/WarningModal";
import Pagination from "../../../components/console/pagination/Pagination";
import EmptyList from "../../../components/console/empties/EmptyList";
import ReviewItem from "./ReviewItem/ReviewItem";
import swal from "@/helper/swal";
import InputSearchNew from "@/components/console/inputs/InputSearchNew.vue";

export default {
  name: "ClubShow",
  components: {
    InputSearchNew,
    Pagination,
    WarningModal,
    ArrowIcon,
    ClubSessionItem,
    ClubMemberItem,
    ButtonTab,
    ClubCard,
    PageLoading,
    PageHeader,
    EmptyList,
    ReviewItem,
  },
  props: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const clubResourceId = route.params.clubResourceId;

    const state = reactive({
      pageLoading: true,
      club: computed(() => {
        return store.getters["newClubs/newClub"];
      }),
      clubMembers: computed(() => {
        return store.getters["newClubs/newClubMembers"].filter((item) => {
          return !item.isHost;
        });
      }),
      clubMembersMeta: computed(() => {
        return store.getters["newClubs/newClubMembersMeta"];
      }),
      searchKey: "",
      allChecked: false,
      activeTab: "session",
      paymentChartOption: {
        series: [44],
        chartOptions: {
          colors: ["#00A3FF"],
          title: {
            text: "구매 전환율",
            style: {
              fontSize: "16px",
              fontWeight: 700,
              color: "#0D0D10",
            },
          },
          chart: {
            type: "radialBar",
          },
          labels: [""],
          plotOptions: {
            radialBar: {
              width: 50,
              offsetY: 34,
              startAngle: -90,
              endAngle: 90,
              hollow: {
                margin: 15,
                size: "70%",
                imageWidth: 300,
              },
              track: {
                background: "#F6F8FA",
                startAngle: -90,
                endAngle: 90,
              },
              dataLabels: {
                show: "always",
                name: {
                  show: true,
                  color: "#888",
                  fontSize: "13px",
                },
                value: {
                  show: true,
                  color: "#00A3FF",
                  fontSize: "30px",
                  offsetY: -30,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
      genderChartOption: {
        series: [
          {
            data: [
              {
                x: "남성",
                y: "28%",
                fillColor: "#ECF1F4",
                color: "#818287",
              },
              {
                x: "여성",
                y: "72%",
                fillColor: "#00A3FF",
                color: "#FFFFFF",
              },
            ],
          },
        ],
        chartOptions: {
          title: {
            text: "구매자 성별",
            style: {
              fontSize: "16px",
              fontWeight: 700,
              color: "#0D0D10",
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
            offsetY: -10,
          },

          grid: {
            show: false,
            xaxis: { lines: { show: false } },
            padding: {
              top: 0,
              right: 80,
              bottom: 0,
              left: 80,
            },
          },
          xaxis: {
            show: false,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              style: {
                colors: ["#818287", "#0D0D10"],
                fontSize: "12px",
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            show: false,
          },
          plotOptions: {
            bar: {
              borderRadius: 8,
              distributed: true,
              columnWidth: "20%",
              dataLabels: {
                position: "top", // top, center, bottom
                colors: ["#818287", "#ffffff"],
              },
            },
          },
          dataLabels: {
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -50,
            style: {
              fontSize: "16px",
              colors: ["#ECF1F4", "#00A3FF"],
              fontWeight: 700,
            },
            background: {
              enabled: true,
              foreColor: "#818287",
              padding: 12,
              borderRadius: 4,
            },
          },
          legend: {
            show: false,
          },
          stroke: {
            curve: "stepline" + "",
          },
        },
      },
      showLiveFinishWarningModal: false,
      isArticles: computed(() => {
        return (
          state.club?.community?.articles.length > 0 &&
          state.club?.community.boardType === "EXPECT"
        );
      }),
    });

    // TODO 우선 기존 api 로 데이터 불러오기
    onBeforeMount(() => {
      setClubShowPage();
    });

    const setClubShowPage = () => {
      store
        .dispatch("newClubs/getClub", { clubResourceId: clubResourceId })
        .then(() => {
          state.pageLoading = false;
        });

      getClubMembers();
    };

    const getClubMembers = (data) => {
      store.dispatch("newClubs/getClubMembers", {
        clubResourceId: clubResourceId,
        params: {
          page: data ? data.page : 1,
          q: state.searchKey,
        },
      });
    };

    onMounted(() => {
      if (route.query && route.query.tab) {
        state.activeTab = route.query.tab;
      }
    });

    const actions = {
      goToClubEdit: () => {
        router.push({
          name: "console.ent.clubs.edit",
          params: {
            clubResourceId: clubResourceId,
          },
        });
      },
      changeTab: (tab) => {
        state.activeTab = tab;
      },
      openLiveFinishModal: () => {
        state.showLiveFinishWarningModal = true;
      },
      closeLiveFinishModal: () => {
        state.showLiveFinishWarningModal = false;
      },
      confirmLiveWarning: () => {
        setClubShowPage();
      },
      showArticleReportSuccessModal: () => {
        swal.successToast("신고 되었습니다.");
      },
    };

    return { state, actions, getClubMembers };
  },
};
</script>

<style src="./style.css" scoped></style>
