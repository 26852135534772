<template>
  <div class="session-card">
    <div class="session-info">
      <div class="header">
        <badge
          v-if="!!club.theme"
          :color="state.sessionType.color"
          :text="state.sessionType.text"
        ></badge>

        <span v-if="state.schedule" class="schedule c-text text-gray-third">
          {{ state.schedule }}
        </span>
      </div>

      <div class="featured-img-wrapper">
        <div ref="sessionFeaturedImage" class="featured-img"></div>
      </div>

      <div class="meta-info">
        <div class="header">
          <badge
            v-if="!!club.theme"
            :color="state.sessionType.color"
            :text="state.sessionType.text"
          ></badge>

          <span v-if="state.schedule" class="schedule c-text text-gray-third">
            {{ state.schedule }}
          </span>
        </div>
        <p class="title text-default">{{ state.title }}</p>
        <p class="description text-gray-second">
          {{ state.description }}
        </p>
      </div>
    </div>

    <div class="btn-wrapper">
      <button
        :style="state.buttonObj.style"
        :disabled="state.buttonObj.disabled"
        @click="actions.onBtnSessionView()"
      >
        {{ state.buttonObj.text }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted, reactive, ref } from "vue";
import moment from "moment-timezone";
import SsoService from "../../../../services/SsoService";
import { useStore } from "vuex";
import Badge from "../../../../components/console/badges/Badge.vue";

export default {
  name: "ClubSessionItem",
  components: { Badge },
  props: {
    session: {
      type: Object,
      required: true,
    },
    club: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emit: ["openLiveFinishModal"],
  setup(props, { emit }) {
    const sessionFeaturedImage = ref();
    const { proxy } = getCurrentInstance();

    const store = useStore();

    const state = reactive({
      userAlias: computed(() => {
        return store.getters["auth/aliasName"];
      }),
      sessionType: computed(() => {
        if (!props.club.theme) {
          return {
            text: "",
            color: "",
          };
        }
        if (props.club.theme.typeLabel === "VOD") {
          return proxy.$const.sessionTypes.vod;
        }
        if (props.club.theme.typeLabel === "Live") {
          // TODO 임시 빅피쳐 라이브 세션에 VOD 콘텐츠 세션 등록 대응
          if (props.session.content) {
            return proxy.$const.sessionTypes.vod;
          }
          return proxy.$const.sessionTypes.live;
        }
        return proxy.$const.sessionTypes.content;
      }),
      buttonObj: computed(() => {
        let result = {
          text: "보기",
          style: {
            backgroundColor: "#ECF1F4",
            color: "#0D0D10",
          },
          disabled: false,
        };

        if (state.isFinishLive) {
          result.text = "라이브 종료";
          result.disabled = true;
          result.style.backgroundColor = "#ECF1F4";
          result.style.color = "#D0D5DE";
        }
        if (props.session.meeting && !state.isFinishLive) {
          result.text = "라이브 입장";
          result.style.backgroundColor = "#8E1EFF";
          result.style.color = "#ffffff";
        }

        return result;
      }),

      schedule: computed(() => {
        if (props.session.meeting) {
          return `${moment(props.session.meeting.scheduledAt).format(
            "YYYY.MM.DD, hh:mm"
          )}~${moment(props.session.meeting.scheduledFinishAt)
            .tz(moment.tz.guess())
            .format("YYYY.MM.DD, hh:mm z")}`;
        }
        return "";
      }),
      title: "",
      description: "",
      isFinishLive: computed(() => {
        return (
          props.session.meeting &&
          moment().diff(moment(props.session.meeting.scheduledFinishAt)) > 0
        );
      }),
    });

    onMounted(() => {
      if (props.session.meeting) {
        sessionFeaturedImage.value.style.backgroundImage = `url(${props.session.meeting.featuredImage})`;
        state.title = props.session.meeting.title;
        state.description = props.session.meeting.description;
      }
      if (props.session.content) {
        sessionFeaturedImage.value.style.backgroundImage = `url(${props.session.content.featuredImage})`;
        state.title = props.session.content.title;
        state.description = props.session.content.description;
      }
    });

    const onBtnSSO = () => {
      const liveUrl = process.env.VUE_APP_LIVE_URL;
      const redirect = `${liveUrl}/${props.session.meeting.resourceId}`;
      const ssoEndPoint = `${liveUrl}/auth/sso`;

      SsoService.redirect(ssoEndPoint, redirect);
    };

    const actions = {
      onBtnSessionView: () => {
        if (state.buttonObj.text === "라이브 입장") {
          if (
            props.session.meeting &&
            moment().diff(moment(props.session.meeting.scheduledFinishAt)) > 0
          ) {
            emit("openLiveFinishModal");
            return;
          } else {
            onBtnSSO();
            return;
          }
        }
        if (state.buttonObj.text === "라이브 종료") {
          return;
        }

        if (state.buttonObj.text === "보기") {
          let routePath = `${process.env.VUE_APP_WEB_URL}/${state.userAlias}/clubs/${props.club.resourceId}/sessions/${props.session.resourceId}`;
          window.open(routePath, "_blank");
        }
      },
    };

    return { state, actions, sessionFeaturedImage };
  },
};
</script>

<style src="./style.css" scoped></style>
