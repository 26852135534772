<template>
  <icon-base
    icon-name="export-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 13.1992C3.22386 13.1992 3 12.9754 3 12.6992L3 4.69922C3 4.42308 3.22386 4.19922 3.5 4.19922L7.5 4.19922C7.77614 4.19922 8 3.97536 8 3.69922C8 3.42308 7.77614 3.19922 7.5 3.19922L3.5 3.19922C2.67157 3.19922 2 3.87079 2 4.69922L2 12.6992C2 13.5276 2.67157 14.1992 3.5 14.1992L11.5 14.1992C12.3284 14.1992 13 13.5276 13 12.6992L13 8.69922C13 8.42308 12.7761 8.19922 12.5 8.19922C12.2239 8.19922 12 8.42308 12 8.69922L12 12.6992C12 12.9754 11.7761 13.1992 11.5 13.1992L3.5 13.1992ZM7.8534 8.34546C7.65814 8.1502 7.65814 7.83362 7.8534 7.63836L12.2927 3.19911L9.96423 3.19911C9.68809 3.19911 9.46423 2.97525 9.46423 2.69911C9.46423 2.42296 9.68809 2.19911 9.96423 2.19911L13.4903 2.19911C13.6214 2.19661 13.7533 2.24539 13.8534 2.34546C13.9534 2.44548 14.0022 2.57732 13.9998 2.70839L13.9998 6.23464C13.9998 6.51078 13.7759 6.73464 13.4998 6.73464C13.2236 6.73464 12.9998 6.51078 12.9998 6.23464L12.9998 3.90621L8.56051 8.34546C8.36525 8.54072 8.04866 8.54072 7.8534 8.34546Z"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "ExportIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#818287",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
