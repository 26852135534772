<template>
  <icon-base
    icon-name="smile-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      :d="state.d"
      :fill="fillColor"
    />
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "SmileIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "-1 -1 14 14",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#818287",
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      d: computed(() => {
        if (props.fill) {
          return "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM8.54567 6.98317C8.75932 7.10669 8.83238 7.38001 8.70886 7.59366C8.43407 8.06893 8.03909 8.46356 7.56357 8.73791C7.08804 9.01227 6.54871 9.1567 5.99971 9.1567C5.45072 9.15671 4.91139 9.01229 4.43586 8.73794C3.96033 8.46359 3.56534 8.06898 3.29055 7.5937C3.16702 7.38006 3.24008 7.10674 3.45372 6.98321C3.66736 6.85969 3.94069 6.93275 4.06421 7.14639C4.26053 7.48593 4.54272 7.76786 4.88245 7.96386C5.22218 8.15986 5.60749 8.26304 5.99971 8.26303C6.39192 8.26303 6.77723 8.15985 7.11696 7.96384C7.45669 7.76783 7.73887 7.4859 7.93519 7.14635C8.0587 6.93271 8.33203 6.85965 8.54567 6.98317ZM3.92278 5.53842C4.30513 5.53842 4.61508 5.22847 4.61508 4.84612C4.61508 4.46377 4.30513 4.15381 3.92278 4.15381C3.54043 4.15381 3.23047 4.46377 3.23047 4.84612C3.23047 5.22847 3.54043 5.53842 3.92278 5.53842ZM8.07668 5.53846C8.45903 5.53846 8.76899 5.22851 8.76899 4.84615C8.76899 4.4638 8.45903 4.15385 8.07668 4.15385C7.69433 4.15385 7.38437 4.4638 7.38437 4.84615C7.38437 5.22851 7.69433 5.53846 8.07668 5.53846Z";
        } else {
          return "M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM4.61538 4.84615C4.61538 5.22851 4.30543 5.53846 3.92308 5.53846C3.54073 5.53846 3.23077 5.22851 3.23077 4.84615C3.23077 4.4638 3.54073 4.15385 3.92308 4.15385C4.30543 4.15385 4.61538 4.4638 4.61538 4.84615ZM8.76929 4.84619C8.76929 5.22854 8.45933 5.5385 8.07698 5.5385C7.69463 5.5385 7.38467 5.22854 7.38467 4.84619C7.38467 4.46384 7.69463 4.15388 8.07698 4.15388C8.45933 4.15388 8.76929 4.46384 8.76929 4.84619ZM8.54597 6.98315C8.75962 7.10667 8.83268 7.38 8.70916 7.59364C8.43437 8.06892 8.03939 8.46354 7.56387 8.7379C7.08834 9.01225 6.54901 9.15668 6.00002 9.15668C5.45102 9.15669 4.91169 9.01227 4.43616 8.73792C3.96063 8.46358 3.56564 8.06896 3.29085 7.59369C3.16732 7.38004 3.24038 7.10672 3.45402 6.9832C3.66766 6.85967 3.94099 6.93273 4.06451 7.14637C4.26083 7.48591 4.54302 7.76784 4.88275 7.96384C5.22248 8.15984 5.60779 8.26302 6.00001 8.26301C6.39222 8.26301 6.77753 8.15983 7.11726 7.96382C7.45699 7.76781 7.73917 7.48588 7.93549 7.14634C8.05901 6.93269 8.33233 6.85963 8.54597 6.98315Z";
        }
      }),
    });

    return { state };
  },
};
</script>

<style scoped></style>
